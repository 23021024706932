<!-- @format -->

<template>
	<div>
		<layout-wide>
			<sub-navbar page="Reminder" />
			<HeroBox
				title="Send To Doubtful Receivables"
				subtitle="Please Select an invoice to send to doubtful receivables"
				color="is-danger"
			/>
			<div></div>
			<div>
				<div v-if="loading">...Loading Please wait!!!</div>
				<div v-if="!loading">
					<div class="table-color color: white">
						<div>
							Unpaid Customer's Invoices Process - Send to Doubtful Receivables
						</div>
						<div>
							<b-button
								class="margin-top-20 width-300 text-bolder"
								style="background-color: rgb(52, 136, 206); color: white"
								@click="refreshData"
							>
								Refresh the data
							</b-button>
							<b-button
								v-if="showWhich === 'notSent'"
								class="margin-top-20 margin-left-15 width-300"
								style="background-color: rgb(61, 196, 134); color: white"
								@click="getSentToDR()"
							>
								Get the invoices sent to DR
							</b-button>
							<b-button
								v-if="showWhich === 'sent'"
								class="margin-top-20 margin-left-15 width-300"
								style="background-color: rgb(182, 90, 92); color: white"
								@click="getNotSentToDR()"
							>
								Get invoices not sent to DR
							</b-button>
						</div>
						<div style="float: right; margin-top: -30px">
							{{ tag }}: {{ roundedCurrencyAddedNumber(totalBalance * 100) }}
						</div>
					</div>
					<div>
						<vue-good-table
							:key="keyTable"
							:columns="columns"
							:rows="rows"
							styleClass="vgt-table striped bordered"
							theme="black-rhino"
							:fixed-header="true"
							@on-row-dblclick="onRowDoubleClick"
							@on-cell-click="onCellClick"
							:search-options="{
								enabled: true,
							}"
							:pagination-options="{
								enabled: true,
								mode: 'records',
								perPage: 100,
								position: 'bottom',
								perPageDropdown: [5, 10, 15, 20, 100],
								dropdownAllowAll: false,
								setCurrentPage: 1,
								jumpFirstOrLast: true,
								firstLabel: 'First',
								lastLabel: 'Last',
								nextLabel: 'next',
								prevLabel: 'prev',
								rowsPerPageLabel: 'Rows per page',
								ofLabel: 'of',
								pageLabel: 'page', // for 'pages' mode
								allLabel: 'All',
							}"
						>
							<template slot="table-row" slot-scope="props">
								<span v-if="props.column.field === 'DR'">
									<b-button class="is-small is-info">{{ doWhat }}</b-button>
								</span>
								<span v-else-if="props.column.field === 'reminderDueDate'">
									<span class="text-bold text-purple">
										{{ props.row.reminderDueDate }}
									</span>
								</span>
								<span v-else-if="props.column.field === 'invoiceBalance'">
									<p class="text-bold text-red text-align-right">
										{{ props.row.invoiceBalance }}
									</p>
								</span>
								<span v-else-if="props.column.field === 'invoiceNumber'">
									<p class="text-bold has-text-link text-align-right">
										<a href="url" style="text-decoration-line: underline">
											{{ props.row.invoiceNumber }}
										</a>
									</p>
								</span>
								<span v-else-if="props.column.field === 'reminderId'">
									<p class="text-bold has-text-link text-align-right">
										<a href="url" style="text-decoration-line: underline">
											{{ props.row.reminderId }}
										</a>
									</p>
								</span>
								<span v-else>
									{{ props.formattedRow[props.column.field] }}
								</span>
							</template>
						</vue-good-table>
					</div>
					<div
						class="table-color"
						style="margin-top: -30px; min-height: 120px; margin-bottom: 50px"
					>
						<download-csv :data="rows" name="DR-list.csv">
							<div style="float: right">
								<b-button
									type="is-danger"
									outlined
									class="width-250 margin-bottom-20; margin-top-30"
								>
									Download the List
								</b-button>
							</div>
						</download-csv>
					</div>
				</div>
			</div>
		</layout-wide>
	</div>
</template>

<script>
import { useMutation, useQuery } from '@vue/apollo-composable'
import SubNavbar from '@/_srcv2/components/_shared/sub-navbar/SubNavbar.vue'
import HeroBox from '@/_srcv2/pages/admin/_shared/HeroBox.vue'
import Store from '@/store'
import GetUnpaidInvoicesByOptionsQuery from '@/_srcv2/pages/reminder/graphql/GetUnpaidInvoicesByOptionsQuery.graphql'
import SendToDoubtfulReceivablesMutation from '@/_srcv2/pages/reminder/graphql/SendToDoubtfulReceivablesMutation.gql'
import { computed, onMounted, reactive, ref } from '@vue/composition-api'
import storeAccounting from '@/_srcv2/pages/_pages-store/storeShared.js'
import moment from 'moment'

export default {
	name: 'SendToDoubtfulReceivables',
	components: {
		SubNavbar,
		HeroBox,
	},
	setup() {
		const doWhat = ref('Send to DR')
		const keyTable = ref(1)
		const getDueDate = () => {
			let date = new Date()
			let daysToSubtract
			switch (moment(date).day()) {
				// Sunday = 4 days
				case 0:
					daysToSubtract = 4
					break
				// Monday and Tuesday = 5 days
				case 1:
				case 2:
					daysToSubtract = 5
					break
				// Subtract 2 days otherwise.
				default:
					daysToSubtract = 3
					break
			}
			return moment(date).subtract(daysToSubtract, 'days').format('YYYY-MM-DD')
		}
		// const getStarForOverdueInvoices = (invoiceDueDate) => {
		// 	if (invoiceDueDate <= getDueDate()) {
		// 		return '*'
		// 	} else return ''
		// }

		// todo Get Data from database
		const options = reactive({
			fetchPolicy: 'no-cache',
		})
		const variablesForTable = reactive({
			is_invoice_paid: 'false',
			our_company: Store.getters.getUserCurrentCompany,
			is_doubtful_receivable: false,
			invoice_due_date: getDueDate(),
		})
		const setVariablesForTable = (isDR) => {
			return new Promise((resolve, reject) => {
				if (variablesForTable.our_company !== null || undefined) {
					variablesForTable.is_invoice_paid = 'false'
					variablesForTable.our_company = Store.getters.getUserCurrentCompany
					variablesForTable.is_doubtful_receivable = isDR
					variablesForTable.invoice_due_date = getDueDate()
					resolve(result)
				} else {
					const reason = new Error('Data could not be fetched from database')
					reject(reason)
				}
			})
		}
		onMounted(() => {
			console.log('mounted getDueDate()', getDueDate())
			setVariablesForTable(false)
			showWhich.value = 'notSent'
			keyTable.value = keyTable.value + 1
		})
		const { result, loading, onResult, refetch } = useQuery(
			GetUnpaidInvoicesByOptionsQuery,
			() => variablesForTable,
			options,
		)
		// const searchedInvoices = useResult(
		// 	result,
		// 	null,
		// 	(data) => data.document_transactions,
		// )
		// -----------------------------------------------------------------------------
		const getSentToDR = () => {
			setVariablesForTable(true).then(() => refetch())
			showWhich.value = 'sent'
			doWhat.value = 'Draw back'
			keyTable.value = keyTable.value + 1
			tag.value = `Total Balance of invoices sent to DR: `
		}
		const getNotSentToDR = () => {
			setVariablesForTable(false).then(() => refetch())
			doWhat.value = 'Send to DR'
			showWhich.value = 'notSent'
			keyTable.value = keyTable.value + 1
			tag.value = `Total Balance of unpaid overdue invoices: `
		}
		// Create the table columns
		const columns = reactive([
			// {
			// 	label: '*',
			// 	field: 'isItOverdue',
			// 	width: '15px',
			// },
			{
				label: 'Invoice Number',
				field: 'invoiceNumber',
				width: '115px',
			},
			{
				label: 'Customer',
				field: 'customer',
			},
			{
				label: 'Invoice due date',
				field: 'invoiceDueDate',
				width: '125px',
			},
			{
				label: 'Reminder Number',
				field: 'reminderId',
				width: '115px',
			},
			{
				label: 'Reminder due date',
				field: 'reminderDueDate',
				width: '125px',
				html: true,
			},
			{
				label: 'Invoice Balance',
				field: 'invoiceBalance',
				width: '125px',
				html: true,
			},
			{
				label: 'DR',
				field: 'DR',
				width: '50px',
				sortable: false,
			},
		])
		// todo Create the table rows

		const { invoiceBalance, roundedCurrencyAddedNumber } = storeAccounting()
		const rows = computed(() =>
			searchedInvoices.value
				.map((row) => {
					return {
						customer:
							row.customer.customer_id +
							' - ' +
							row.customer.customer_title +
							' - ' +
							row.customer.customer_nickname,
						invoiceNumber: row.invoice_number,
						invoiceDate: row.invoice_date,
						invoiceDueDate: row.invoice_due_date,
						reminderId: row?.reminder_lines?.reminder_id || '',
						reminderDate:
							row?.reminder_lines?.reminder_header?.reminder_date || '',
						reminderDueDate:
							row?.reminder_lines?.reminder_header?.reminder_due_date || '',
						invoiceBalance: invoiceBalance(row).correctedBalance,
						// isItOverdue: getStarForOverdueInvoices(row.invoice_date),
					}
				})
				.sort(function (a, b) {
					const invoiceDueDateA = a.invoiceDueDate
					const invoiceDueDateB = b.invoiceDueDate
					if (invoiceDueDateA < invoiceDueDateB) {
						return -1
					}
					if (invoiceDueDateA > invoiceDueDateB) {
						return 1
					}
					// dates must be equal
					return 0
				}),
		)
		// Create the table functions
		const onCellClick = (params) => {
			if (params.column.field === 'DR') {
				switch (doWhat.value) {
					case 'Send to DR':
						getVariables(params.row.invoiceNumber)
							.then(() => {
								sendToDR()
							})
							.then(() => refetch())
						break
					case 'Draw back':
						console.log('Draw back is fired')
						getVariables(params.row.invoiceNumber)
							.then(() => {
								sendToDR()
							})
							.then(() => refetch())
						break
					default:
						return
				}
				return params.row
			}
		}
		const onRowDoubleClick = (params) => {
			return params.row
		}
		// ? Get total balance
		function removeCommasAndFixDecimal(input) {
			// Remove commas using regex
			const stringWithoutCommas = input.replace(/,/g, '')
			// Convert to a float
			const floatValue = parseFloat(stringWithoutCommas)
			return floatValue
		}

		const tag = ref(`Total Balance of unpaid reminded invoices: `)
		const totalBalance = ref(0)
		const searchedInvoices = ref([])
		onResult(() => {
			console.log(' new result is fetched', result.value)
			searchedInvoices.value = result.value.document_transactions
			const arr = rows.value.map((item) =>
				removeCommasAndFixDecimal(item.invoiceBalance),
			)
			console.log('arr', arr)
			totalBalance.value = arr.reduce((a, b) => a + b, 0)
			console.log('totalBalance', totalBalance.value)
		})
		const refreshData = () => {
			refetch().then(() => window.alert(' The data is refreshed'))
		}
		// -------------------------------------------------------------------
		const mutationVariablesSendToDR = reactive({
			invoice_number: '',
			our_company: '',
			is_doubtful_receivable: true,
		})
		const { mutate: sendToDR, onDone: onDoneSendToDR } = useMutation(
			SendToDoubtfulReceivablesMutation,
			() => ({
				variables: mutationVariablesSendToDR,
			}),
		)
		onDoneSendToDR((result) => {
			console.log('onDone:', result.data)
			const message = ref('')
			if (doWhat.value === 'Send to DR') {
				message.value = 'is sent to DR'
			} else {
				message.value = 'is drawn back'
			}
			window.alert(`
      ${result.data.update_document_transactions.returning[0].invoice_number} ${message.value}
      `)
		})

		const getVariables = (invoiceNumber) => {
			return new Promise((resolve, reject) => {
				if (invoiceNumber !== null || undefined) {
					mutationVariablesSendToDR.invoice_number = invoiceNumber
					mutationVariablesSendToDR.our_company =
						Store.getters.getUserCurrentCompany
					if (doWhat.value === 'Send to DR') {
						mutationVariablesSendToDR.is_doubtful_receivable = true
					} else if (doWhat.value === 'Draw back') {
						mutationVariablesSendToDR.is_doubtful_receivable = false
					}
					resolve(mutationVariablesSendToDR)
				} else {
					const reason = new Error(
						'Invoice Number could not be fetched from database',
					)
					reject(reason)
				}
			})
		}
		// todo --------------------------------------------------------

		const showWhich = ref('notSent')
		return {
			loading,
			tag,
			roundedCurrencyAddedNumber,
			totalBalance,
			refreshData,
			columns,
			rows,
			onCellClick,
			onRowDoubleClick,
			getSentToDR,
			getNotSentToDR,
			showWhich,
			keyTable,
			doWhat,
		}
	},
}
</script>

<style scoped></style>
